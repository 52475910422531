import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import AchievementImg from "./AchievementImg";
import "./AchievementComponent.css";
import { Fade } from "react-reveal";
import AchievementContainer from "../../containers/achievement/Achievement";
import { achievementSection } from "../../portfolio";

class Achievement extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="education-main">
        <Header theme={this.props.theme} />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="heading-div">
              <div className="heading-img-div">
                <AchievementImg theme={theme} />
              </div>
              <div className="heading-text-div">
                <h1 className="heading-text" style={{ color: theme.text }}>
                  Achievements
                </h1>
                <h3 className="heading-sub-text" style={{ color: theme.text }}>
                  {achievementSection.subtitle}
                </h3>
                <p
                  className="achievement-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {achievementSection.description}
                </p>
              </div>
            </div>
          </Fade>
          <AchievementContainer theme={this.props.theme} />
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Achievement;
