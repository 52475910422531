import React, { Component } from "react";
import "./Achievement.css";
import AchivementCard from "../../components/achievementCard/AchivementCard";
import { achievementSection } from "../../portfolio";

class Achievement extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="achievements">
        <div className="achievement-main-div">
          <div className="achievement-cards-div">
            {achievementSection.achivementsCards.map((card) => {
              return (
                <AchivementCard
                  cardInfo={{
                    color_code: card.color_code,
                    title: card.title,
                    subtitle: card.subtitle,
                    description: card.description,
                    image: card.image,
                    footer: card.footerLink,
                    theme: theme,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Achievement;
