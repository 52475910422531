/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Gracy's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Gracy Gupta Portfolio",
    type: "website",
    url: "https://gracy.live/",
  },
};

//Home Page
const greeting = {
  title: "Gracy Gupta",
  logo_name: "GracyGupta",
  nickname: "gracygupta",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1rNBo1G4SGfnC3LhQFbu1TXUMBme0ph04/view?usp=drive_link",
  portfolio_repository: "https://github.com/gracygupta/GracyPortfolio",
  githubProfile: "https://github.com/gracygupta",
};

const socialMediaLinks = [
  /* Your Social Media Link */

  {
    name: "Github",
    link: "https://github.com/gracygupta",
    fontAwesomeIcon: "fab fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/gracygupta/",
    fontAwesomeIcon: "fab fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:hzgracy@gmail.com",
    fontAwesomeIcon: "fas fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#EA4335", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  {
    name: "Instagram",
    link: "https://www.instagram.com/it._wasnt._me0410/",
    fontAwesomeIcon: "fab fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive and dynamic website front ends using React-Redux",
        "⚡ Creating robust RESTful APIs for seamless client-server communication",
        "⚡ Designing and managing database schemas",
        "⚡ Integrating third-party APIs and services",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "ExpressJS",
          fontAwesomeClassname: "simple-icons:express",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Postgres",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#E10098",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "simple-icons:redux",
          style: {
            color: "#764ABC",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#430098",
          },
        },
        {
          skillName: "Vercel",
          fontAwesomeClassname: "simple-icons:vercel",
          style: {
            color: "#000000",
          },
        },
      ],
    },
    {
      title: "Cloud Services & Deployment",
      fileName: "CloudServicesImg",
      skills: [
        "⚡ Designing and deploying scalable cloud solutions",
        "⚡ Managing infrastructure and automating deployments",
        "⚡ Managing relational and non-relational databases",
        "⚡ Monitoring and optimizing cloud resources for performance",
      ],
      softwareSkills: [
        {
          skillName: "AWS EC2, Route53, RDS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "AWS S3",
          fontAwesomeClassname: "simple-icons:amazons3",
          style: {
            color: "#569A31",
          },
        },
        // {
        //   skillName: "AWS Route53",
        //   fontAwesomeClassname: "simple-icons:amazonaws",
        //   style: {
        //     color: "#FF9900",
        //   },
        // },
        // {
        //   skillName: "AWS RDS",
        //   fontAwesomeClassname: "simple-icons:amazonaws",
        //   style: {
        //     color: "#FF9900",
        //   },
        // },
        {
          skillName: "Google Cloud Functions",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Postgres",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Azure Virtual Machines",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "simple-icons:redis",
          style: {
            color: "#D82C20",
          },
        },
      ],
    },

    // {
    //   title: "Web Development",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //     {
    //       skillName: "Deeplearning",
    //       imageSrc: "deeplearning_ai_logo.png",
    //     },
    //   ],
    // },
    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
    //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
    //     "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "GCP",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#4285F4",
    //       },
    //     },
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Azure",
    //       fontAwesomeClassname: "simple-icons:microsoftazure",
    //       style: {
    //         color: "#0089D6",
    //       },
    //     },
    //     {
    //       skillName: "Firebase",
    //       fontAwesomeClassname: "simple-icons:firebase",
    //       style: {
    //         color: "#FFCA28",
    //       },
    //     },
    //     {
    //       skillName: "PostgreSQL",
    //       fontAwesomeClassname: "simple-icons:postgresql",
    //       style: {
    //         color: "#336791",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "Kubernetes",
    //       fontAwesomeClassname: "simple-icons:kubernetes",
    //       style: {
    //         color: "#326CE5",
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/hzgracy/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/gracygupta",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/gracygupta04",
    },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Ajay Kumar Garg Engineering College, Ghaziabad",
      subtitle: "B.Tech. in Computer Science",
      logo_path: "akgec_logo.png",
      alt_name: "AKGEC Ghaziabad",
      duration: "2021 - 2025",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Cloud Computing and Full Stack Development.",
        "⚡ I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "https://www.akgec.ac.in/",
    },
    {
      title: "Sunbeam Academy, Varanasi",
      subtitle: "Senior School Certificate Examination",
      logo_path: "sunbeam_logo.png",
      alt_name: "Sunbeam Academy",
      duration: "2020 - 2021",
      descriptions: [
        "⚡ Achieved an impressive 93.8% in the final examinations, demonstrating consistent excellence in academics.",
        "⚡ Gained strong proficiency in Computer Science, including programming fundamentals and computational problem-solving.",
        "⚡ Built early interest in integrating theoretical knowledge with practical applications, especially in computational simulations.",
      ],
      website_link: "https://sunbeamacademysmn.com/",
    },
    {
      title: "Sunbeam Academy, Varanasi",
      subtitle: "Secondary School Examination",
      logo_path: "sunbeam_logo.png",
      alt_name: "Sunbeam Academy",
      duration: "2018 - 2019",
      descriptions: [
        "⚡ Achieved an outstanding 94.6% in the board examinations, reflecting a strong academic foundation.",
        "⚡ Focused on core Science subjects, excelling in topics like Physics, Chemistry, and Mathematics.",
        "⚡ Recognized for consistent academic excellence and a proactive attitude toward self-learning and innovation.",
      ],
      website_link: "https://sunbeamacademysmn.com/",
    },
    // {
    //   title: "Indiana University Bloomington",
    //   subtitle: "M.S. in Computer Science",
    //   logo_path: "iu_logo.png",
    //   alt_name: "Indiana University Bloomington",
    //   duration: "2021 - 2023",
    //   descriptions: [
    //     "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
    //     "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
    //     "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
    //   ],
    //   website_link: "https://www.indiana.edu/",
    // },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Javascript Intermediate",
      subtitle: "- HackerRank",
      logo_path: "js_logo.png",
      certificate_link: "https://www.hackerrank.com/certificates/95f1007c065b",
      alt_name: "Javascript",
      color_code: "#4285F499",
    },
    {
      title: "Node.js & Express.js",
      subtitle: "- IBM",
      logo_path: "node_express_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/SJ6YXSEHC8KS",
      alt_name: "Nodejs & Expressjs",
      color_code: "#8C151599",
    },
    {
      title: "AWS EC2",
      subtitle: "- Udemy",
      logo_path: "ec2_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-325d2b2b-77ce-4b42-aa21-85c7c5264384/",
      alt_name: "AWS",
      color_code: "#2A73CC",
    },
    {
      title: "Reactjs",
      subtitle: "- IBM",
      logo_path: "reactjs_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/DAWMGD8TMW9U",
      alt_name: "Reactjs",
      color_code: "#FFBB0099",
    },
    {
      title: "Git & Github",
      subtitle: "- IBM",
      logo_path: "github_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/TS42FVKDKRRJ",
      alt_name: "Github",
      color_code: "#00000099",
    },
    {
      title: "Web Development with HTML, CSS, JavaScript",
      subtitle: "- IBM",
      logo_path: "htmlcssjs_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/DJTYY3QXHMUV",
      alt_name: "Web Development",
      color_code: "#FFBB0099",
    },
  ],
};

const achievementSection = {
  title: "Achievements",
  subtitle: "Milestones That Define Excellence",
  description:
    "A showcase of my journey through innovation, collaboration, and impact. These achievements highlight my dedication to learning, leadership, and creative problem-solving.",
  achivementsCards: [
    {
      title: "30Hacks",
      subtitle: "- Winner",
      alt_name: "30Hacks Winner",
      description:
        "Winner of Track “SUSTAINABLE ENERGY SOLUTIONS” hosted by GobalLogic x Hitachi",
      image: "30Hacks.png",
      footerLink: [],
      color_code: "#00000099",
    },
    {
      title: "SIH2023",
      subtitle: "- Winner",
      alt_name: "Smart India Hackathon Winner",
      description:
        "Winner of PS 1302 proposed by Ministry of Power at Smart India Hackathon organized by Government of India",
      image: "sih2023.png",
      footerLink: [],
      color_code: "#00000099",
    },
    {
      title: "Shankara Global Hackathon",
      subtitle: "- Winner",
      alt_name: "dfvd",
      description:
        "Won the MOST INNOVATIVE IDEA” title at the Shankara Global Hackathon  by Shankara Global University",
      image: "shankara.png",
      footerLink: [],
      color_code: "#00000099",
    },
    {
      title: "Tech Sprint Hackathon",
      subtitle: "- First Runner-Up",
      alt_name: "Tech Sprint Runner-Up",
      description:
        "Achieved SECOND Position in the TECH SPRINT-HACKATHON organized by Institution's Innovation Council, Ramanujan College",
      image: "ramanujan.jpg",
      footerLink: [],
      color_code: "#00000099",
    },
  ],
};

// const achievementSection = {
//   achievementSection: [
//     {
//       title: "30Hacks",
//       subtitle: "- HackerRank",
//       logo_path: "30Hacks.png",
//       certificate_link: "https://www.hackerrank.com/certificates/95f1007c065b",
//       alt_name: "Javascript",
//       color_code: "#4285F499",
//     },
//     {
//       title: "SIH 2023",
//       subtitle: "- IBM",
//       logo_path: "sih2023.png",
//       certificate_link:
//         "https://www.coursera.org/account/accomplishments/verify/SJ6YXSEHC8KS",
//       alt_name: "Nodejs & Expressjs",
//       color_code: "#8C151599",
//     },
//     {
//       title: "Shankara Global Hackathon",
//       subtitle: "- Udemy",
//       logo_path: "shankara.png",
//       certificate_link:
//         "https://www.udemy.com/certificate/UC-325d2b2b-77ce-4b42-aa21-85c7c5264384/",
//       alt_name: "AWS",
//       color_code: "#2A73CC",
//     }
//   ],
// };

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as Nodejs Developer, and Product Designer. I love organising events and that is why I am also involved with many communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    // {
    //   title: "Work",
    //   work: true,
    //   experiences: [
    //     {
    //       title: "Machine Learning Engineer",
    //       company: "TikTok Inc.",
    //       company_url: "https://www.tiktok.com/en/",
    //       logo_path: "tiktok_logo.png",
    //       duration: "June 2023 - Present",
    //       location: "San Jose, CA, USA",
    //       description:
    //         "Improving ads ranking models on the core TikTok product. Experience working on modeling two-tower architectures like DeepFM, Wide & deep learning, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetuning strategies.",
    //       color: "#000000",
    //     },
    //     // {
    //     //   title: "Associate AI Engineer",
    //     //   company: "Legato Health Technology",
    //     //   company_url: "https://legatohealthtech.com/",
    //     //   logo_path: "legato_logo.png",
    //     //   duration: "June 2020 - Aug 2021",
    //     //   location: "Hyderabad, Telangana",
    //     //   description:
    //     //     "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
    //     //   color: "#0879bf",
    //     // },
    //     // {
    //     //   title: "Android and ML Developer",
    //     //   company: "Muffito Incorporation",
    //     //   company_url: "https://www.linkedin.com/company/muffito-inc/about/",
    //     //   logo_path: "muffito_logo.png",
    //     //   duration: "May 2018 - Oct 2018",
    //     //   location: "Pune, Maharashtra",
    //     //   description:
    //     //     "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
    //     //   color: "#9b1578",
    //     // },
    //     // {
    //     //   title: "Android Developer",
    //     //   company: "FreeCopy Pvt. Ltd.",
    //     //   company_url: "https://www.linkedin.com/company/freecopy/about/",
    //     //   logo_path: "freecopy_logo.png",
    //     //   duration: "Nov 2017 - Dec 2017",
    //     //   location: "Ahmedabad, Gujarat",
    //     //   description:
    //     //     "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
    //     //   color: "#fc1f20",
    //     // },
    //   ],
    // },
    {
      title: "Internships",
      experiences: [
        {
          title: "Product Engineer",
          company: "Arohana Technologies Pvt. Ltd.",
          company_url: "https://myrik.in/",
          logo_path: "myrik_logo.jpeg",
          duration: "Oct 2024 - Present",
          location: "Work from Home",
          description:
            "Designed and developed scalable RESTful APIs using JavaScript and Node.js to streamline data access from SQL and Firebase. Implemented automated workflows by deploying cron jobs on Google Cloud Platform and integrating the WhatsApp Business Graph API for seamless communication. Enhanced user experience by creating dynamic, responsive interfaces with Next.js.",
          color: "#000000",
        },
        {
          title: "SDE Intern",
          company: "BCS Network",
          company_url: "https://www.linkedin.com/company/bcsnetwork/",
          logo_path: "bcs_logo.jpeg",
          duration: "Aug 2024 - Oct 2024",
          location: "Work from Home",
          description:
            "Developed RESTful APIs using JavaScript and Node.js, ensuring efficient data retrieval and manipulation from MongoDB. Demonstrated expertise in the Software Development Life Cycle (SDLC) and Agile methodologies.",
          color: "#ee3c26",
        },
        {
          title: "Nodejs Developer",
          company: "Chaintech Network Pvt. Ltd.",
          company_url: "https://www.chaintech.network/",
          logo_path: "chaintech_network_logo.jpeg",
          duration: "Feb 2024 - July 2024",
          location: "Work From Home",
          description:
            "Acquired expertise in Git, WebRTC, and coding standards; spearheaded backend development, enhanced media streaming protocols, and rolled out scalable solutions, reducing server costs by 20% and improving streaming quality by 30%.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Backend Developer",
          company: "Blockchain Research Lab",
          company_url: "https://brlakgec.com/",
          logo_path: "brl_logo.png",
          duration: "Sep 2022 - Present",
          location: "Ghaziabad, Uttar Pradesh, India",
          description:
            "Contributed as a backend developer to streamline college processes by building robust solutions for admission management and visitor entry registration. Played a pivotal role in organizing events and workshops, fostering a culture of innovation and collaboration among students. Engineered scalable systems to digitize and automate college operations, enhancing efficiency and user experience.",
          color: "#4285F4",
        },
        {
          title: "Disciplinary Committee",
          company: "AKGEC, Ghaziabad",
          company_url: "https://www.akgec.ac.in/",
          logo_path: "dc_logo.jpg",
          duration: "Oct 2023 - Nov 2024",
          location: "Ghaziabad, Uttar Pradesh, India",
          description:
            "Served as a member of the Disciplinary Committee, ensuring compliance with college policies and maintaining decorum during campus events and activities. Assisted in resolving conflicts and enforcing ethical standards to foster a safe and respectful environment. Played an active role in organizing awareness programs to promote discipline and responsibility among students.",
          color: "#D83B01",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create web-based applications and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile_image.png",
    description:
      "Feel free to connect with me on any social media platform! I typically respond within 24 hours. I specialize in MERN stack and web development and am always happy to assist or collaborate.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Varanasi, Uttar Pradesh, India, 221010",
    locality: "Varanasi",
    country: "India",
    region: "Uttar Pradesh",
    postalCode: "221010",
    streetAddress: "Khojwan Bazar",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/uBLso8BcvZVTGFvu9",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  achievementSection,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
