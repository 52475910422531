import React from "react";
import "./AchievementCard.css";
import { Fade } from "react-reveal";

export default function AchivementCard({ cardInfo }) {
  function openUrlInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="certificate-card achievement-card-padding">
        <div
          className="certificate-image-div achievement-header"
          style={{ backgroundColor: cardInfo.color_code }}
        >
          <img
            className="achievement_logo_img"
            src={require(`../../assets/images/${cardInfo.image}`)}
            alt={cardInfo.alt_name}
          />
        </div>
        <div className="certificate-detail-div cert-body">
          <h5 className="cert-body-title">{cardInfo.title}</h5>
          <p className="cert-body-subtitle">{cardInfo.subtitle}</p>
          <p className="cert-body-description">{cardInfo.description}</p>
        </div>
        <div className="certificate-card-footer">
          {cardInfo.footer.map((v, i) => {
            return <p onClick={() => openUrlInNewTab(v.url)}>{v.name}</p>;
          })}
        </div>
      </div>
    </Fade>
  );
}
